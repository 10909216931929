import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import "../components/styles/klaus-gridski.css"

import Layout from "../components/layout"
import { Section, Wrapper, Paragraph } from "../components/styles/container"
import { Grid } from "../components/styles/grid"
import Quote from "../components/quote"
import Mail from "../components/mail"

import Portrait from "../images/about_cropped.jpg"
import Sign from "../images/sign.png"

/******************************************************************************************* STYLED
 *************************************************************************************************/

const MobilePortraitStyle = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  max-height: 400px;
  /* Width in PX > 920px */
  @media only screen and (min-width: 62em) {
    max-height: unset;
    width: 50%;
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
  }
`

const AboutGrid = styled(Grid)`
  height: 100%;
`

const Margin = styled.div`
  margin-top: 3em;
`

const ListStyle = styled.ul`
  max-width: 800px;
  transform: translateX(15px);
  line-height: 2.2;
  list-style-image: url(${Sign});
`

// const Test = styled.div`
//   position: fixed;
//   height: 100%;
//   width: 50%;
//   top: 0;
//   left: 0;
//   z-index: -1;
// `

const TestTwo = styled.img`
  object-fit: cover;
  position: fixed;
  height: 100%;
  width: 50%;
  top: 0;
  left: 0;
  z-index: -1;
`

const BigGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media only screen and (min-width: 62em) {
    grid-template-columns: 1fr 1fr;
  }
`

/******************************************************************************************* ABOUT
 *************************************************************************************************/

const About = () => {
  return (
    <Layout>
      <SEO title="Über mich" />
      <BigGrid>
        <div className="bg-disappear">
          <AboutGrid>
            <MobilePortraitStyle
              alt="Christina Pabst Portrait"
              className="img-opacity"
              src={Portrait}
            />
          </AboutGrid>
        </div>

        <div className="disappear bg-appear">
          <TestTwo src={Portrait} />
        </div>

        <div>
          <Section>
            <Wrapper>
              <h2>Christina Pabst</h2>
              <h3>optimistisch | zielorientiert | nahbar | motiviert</h3>
              <Paragraph>
                Ich bin Christina Pabst und unterstütze Sie in den Bereichen
                Coaching, Mediation und Training. <br /> Geboren bin ich 1977 in
                Alsfeld und über einen Aufenthalt im Rhein-Main-Gebiet dem
                Vogelsberg immer treu geblieben. Nach Feierabend begeistere ich
                mich für meine Familie (vor allem meine Tochter), Freunde und
                Sport. <br /> <br />
                In den letzten 20 Berufsjahren, durfte ich (und darf es bis
                heute) bei Randstad Deutschland verschiedene Arbeitsbereiche,
                Interessen, Branchen und Teams kennenlernen. Daher ist mir die
                Arbeitswelt mit den mannigfaltigen Herausforderungen bestens
                vertraut.
                <br /> Meine Leidenschaft ist es Menschen in unterschiedlichen,
                nicht nur beruflichen Situationen, zu begleiten und weiter zu
                bringen. Das hat mich motiviert mein eigenes Unternehmen
                aufzubauen, um auch außerhalb meines Angestelltenverhältnisses
                als Sparringspartner aktiv zu werden.
              </Paragraph>{" "}
              <Margin>
                <h3>Aktuelle Qualifizierungen: </h3>
                <ListStyle>
                  <li>
                    Zertifikat Mediation und Coaching (Univ.) / Europäischer
                    Hochschulverbund in Zusammenarbeit mit dem Institut für
                    Mediative Kommunikation und Diversity Kompetenz
                  </li>
                  <li>Der komplette Verhandler / The Gap Partnership</li>
                  <li>Leadership & Personality / Stand & Deliver Group </li>
                  <li>Senior Leadership Program / Pawlik</li>
                </ListStyle>
              </Margin>
              <Margin>
                <h3>Berufsweg und Expertise:</h3>
                <ListStyle>
                  <li>
                    bis heute: Aufbau der Geschäftsbeziehung zu nationalen und
                    internationalen Großkunden
                  </li>
                  <li>
                    Interner Coach, Mediator und Trainer der Randstad Business
                    School
                  </li>
                  <li>Ausbau einer Spezialisierung</li>
                  <li>Führungsrolle im Key Account Management</li>
                  <li>Key Account Manager</li>
                  <li>Management Trainee der Geschäftsführung</li>
                  <li>Vertriebs-Trainerin in der Personalentwicklung </li>
                  <li>Disponentin</li>
                  <li>Ausbildereignungsprüfung</li>
                  <li>Kaufmännische Ausbildung im Handel </li>
                </ListStyle>
              </Margin>
              <Quote author="Lexis Carrel">
                “Es kommt nicht darauf an, dem Leben mehr Jahre zu geben,
                sondern den Jahren mehr Leben zu geben.”
              </Quote>
              <Mail />
            </Wrapper>
          </Section>
        </div>
      </BigGrid>
    </Layout>
  )
}

export default About
